import React from 'react'
import { graphql } from 'gatsby'

/*
import Slider from "react-slick"
import uuid from 'uuid'
import ReactBnbGallery from 'react-bnb-gallery'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
 */

import Layout from '../components/Layout'
import SEO from '../components/seo'
//import BgImage from "../components/BgImage"
import BookingForm from "../components/BookingForm"
import PageBanner from '../components/PageBanner'

const BlogTemplate = ({data}) => {

  const post_meta = data.markdownRemark.frontmatter;

  return (
    <Layout
      sidebarComponent={<BookingForm colWidth="col-md-12"/>}
      className="detail-page"
    >
      <SEO title={post_meta.title}/>

      <PageBanner image={post_meta.thumb.childImageSharp.fluid.srcWebp} title={post_meta.title}/>

      <div
        className="inner-content blog-post-content"
        dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
      ></div>


    </Layout>
  )

}

export default BlogTemplate

export const BlogPageQuery = graphql`
  query ($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      id
      html
      frontmatter {
        title
        path
        date
        thumb {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1600, maxHeight: 400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`

